/* eslint-disable prettier/prettier */
import { SUCCESS_SUFFIX } from 'redux-axios-middleware';
import HttpService from '../services/HttpService';
import UserService from '../services/UserService';

const LIST_TANKS = 'LIST_TANKS';
const LIST_TANKS_BY_EMAIL = 'LIST_TANKS_BY_EMAIL';
const ADD_TANK = 'ADD_TANK';
const DELETE_TANK = 'DELETE_TANK';
const GET_MEASUREMENTS = 'GET_MEASUREMENTS';
const GET_DASHBOARD = 'GET_DASHBOARD';

const tanksReducer = (state = [], action) => {
  switch (action.type) {
    case LIST_TANKS + SUCCESS_SUFFIX:
      return action.payload.data;

    case LIST_TANKS_BY_EMAIL + SUCCESS_SUFFIX:
      return action.payload.data;
      
    case GET_MEASUREMENTS + SUCCESS_SUFFIX:
      return action.payload.data;
      
    case GET_DASHBOARD + SUCCESS_SUFFIX:
      return action.payload.data;

    case DELETE_TANK:
      return state.filter((tank) => tank.id !== action.payload.tank.id);

    default:
      return state;
  }
};

export default tanksReducer;

export const allTanks = () => ({
  type: LIST_TANKS,
  payload: {
    request: {
      url: '/api/users/tanks',
    },
  },
});

export const allTanksByEmail = () => ({
  type: LIST_TANKS_BY_EMAIL,
  payload: {
    request: {
      url: '/api/users/tanksByEmail',
    },
  },
});

export const addTank = tank => {
  console.log(`${UserService.getUsername()} added the tank ${tank.name}`);
  return {
    type: ADD_TANK,
    payload: {
      request: {
        url: '/api/admin/tanks/${tankFarmId}',
        method: HttpService.HttpMethods.POST,
        data: tank,
      },
    },
  }
};

export const deleteTank = tank => {
  console.log(`${UserService.getUsername()} deletes the tank ${tank.name}`);
  return {
    type: DELETE_TANK,
    payload: {
      tank,
      request: {
        url: `/api/admin/tanks/${tank.id}`,
        method: HttpService.HttpMethods.DELETE,
      },
    },
  }
};

export const getMeasurements = (tank) => ({
  type: GET_MEASUREMENTS,
  payload: {
    tank,
    request: {
      url: `/api/users/measurements/${tank.id}`,
    },
  },
});

export const getDashboard = () => ({
  type: GET_DASHBOARD,
  payload:{
      request: {
          url:`/api/users/dashboard`
      },
  },
});