/* eslint-disable prettier/prettier */
import { combineReducers } from 'redux';
import tanks from './tanks'
import tankFarms from './tankFarms'
import users from './users'
import tkDashboard from './tkDashboard';

export default combineReducers({
    tanks,
    tankFarms,
    users,
    tkDashboard,
})
