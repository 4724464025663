/* eslint-disable prettier/prettier */
import { SUCCESS_SUFFIX } from 'redux-axios-middleware';
//import HttpService from '../services/HttpService';
//import UserService from '../services/UserService';

const GET_DASHBOARD = 'GET_DASHBOARD';
const tkDashboardReducer = (state = [], action) => {
    switch (action.type) {
        case GET_DASHBOARD + SUCCESS_SUFFIX:
            return action.payload.data;
        default:
            return state;
    }
}

export default tkDashboardReducer;

export const getDashboard = () => ({
    type: GET_DASHBOARD,
    payload:{
        request: {
            url:`/api/users/dashboard`
        },
    },
});