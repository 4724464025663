/* eslint-disable prettier/prettier */
import { SUCCESS_SUFFIX } from 'redux-axios-middleware';
import HttpService from '../services/HttpService';
import UserService from '../services/UserService';

const LIST_TANKFARMS = 'LIST_TANKFARMS';
const ADD_TANKFARM = 'ADD_TANKFARM';
const DELETE_TANKFARM = 'DELETE_TANKFARM';

const tankFarmsReducer = (state = [], action) => {
    switch (action.type) {
      case LIST_TANKFARMS + SUCCESS_SUFFIX:
        return action.payload.data;
  
      case DELETE_TANKFARM:
        return state.filter((tankFarm) => tankFarm.id !== action.payload.tankFarm.id);
  
      default:
        return state;
    }
  };

  export default tankFarmsReducer;

  export const allTankFarms = () => ({
    type: LIST_TANKFARMS,
    payload: {
      request: {
        url: '/api/users/tankFarms',
      },
    },
  });

  export const addTankFarm = tankFarm => {
    console.log(`${UserService.getUsername()} added the tank ${tankFarm.name}`);
    return {
      type: ADD_TANKFARM,
      payload: {
        request: {
          url: '/api/admin/tankFarms/${tankFarmId}',
          method: HttpService.HttpMethods.POST,
          data: tankFarm,
        },
      },
    }
  };
  
  export const deleteTankFarm = tankFarm => {
    console.log(`${UserService.getUsername()} deletes the tankFarm ${tankFarm.name}`);
    return {
      type: DELETE_TANKFARM,
      payload: {
        tankFarm,
        request: {
          url: `/api/admin/tankFarms/${tankFarm.id}`,
          method: HttpService.HttpMethods.DELETE,
        },
      },
    }
  };