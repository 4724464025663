/* eslint-disable prettier/prettier */
import React, { Component } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import './scss/style.scss'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import RenderOnAuthenticated from './helpers/RenderOnAuthenticated'
import StoreService from './services/StoreService'
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))



const theme = createTheme({
  typography: {
    tab: {
      fontSize: 20,
    },
  },
  palette: {
    common: {
      purple: 'purple',
    },
  },
})
const store = StoreService.setup();
const queryClient = new QueryClient()

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <HashRouter>
            <React.Suspense fallback={loading}>
              <Switch>
                <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                <Route
                  exact
                  path="/register"
                  name="Register Page"
                  render={(props) => <Register {...props} />}
                />
                <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
 
                  <Route path="/" name="Home" render={(props) => <DefaultLayout {...props} />} />


              </Switch>
            </React.Suspense>
          </HashRouter>
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
        </QueryClientProvider>
      </Provider>
    )
  }
}

export default App
