/* eslint-disable prettier/prettier */
import Keycloak from 'keycloak-js';

const _kc = new Keycloak('/keycloak.json');

/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param onAuthenticatedCallback
 */
const initKeycloak = (onAuthenticatedCallback) => {
  _kc.init({   
    //onLoad: 'check-sso',
    onLoad: 'login-required',
   // silentCheckSsoRedirectUri: window.location.origin + '/silent-check-sso.html',
   // pkceMethod: 'S256',
  })
    .then((authenticated) => {
      if (!authenticated) {
        console.log('user is not authenticated..!');
      }
      console.log('initialisation');
      onAuthenticatedCallback();
    })
    .catch(console.error);
};

const doLogin = _kc.login;

const doLogout = _kc.logout;

const getToken = () => _kc.token;

const isLoggedIn = () => !!_kc.token;

const updateToken = (successCallback) =>
  _kc.updateToken(5)
    .then(successCallback)
    .catch(doLogin);

const getUsername = () => _kc.tokenParsed?.preferred_username;

const hasRole = (roles) => roles.some((role) => _kc.hasRealmRole(role));
const isRole= (role) => _kc.hasRealmRole(role);
//const isRole = (roles) =>  (roles==="ADMIN")? true: false 
const UserService = {
  initKeycloak,
  doLogin,
  doLogout,
  isLoggedIn,
  getToken,
  updateToken,
  getUsername,
  hasRole,
  isRole,
  _kc,
};

export default UserService;