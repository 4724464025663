import { createStore } from 'redux'
import StoreService from './services/StoreService'

const user = StoreService.setup()

const initialState = {
  sidebarShow: true,
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const store = createStore(changeState, user)
export default store
