/* eslint-disable prettier/prettier */
//import 'react-app-polyfill/stable'
//import 'core-js'
import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
//import * as serviceWorker from './serviceWorker'
import { Provider } from 'react-redux'
import store from './store'
import HttpService from './services/HttpService'
import UserService from './services/UserService'
//import { keycloak} from './services/UserService/_kc'
import Login from './views/pages/login/Login'

 //React Render
 //ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById("root"));

//const store = StoreService.setup()

  let keycloak = UserService._kc
   keycloak.init({ onLoad: 'login-required'}).success((auth) => {

    if (!auth) {
        window.location.reload();
    } else {
        console.info("Authenticated");
    }

    //React Render
    ReactDOM.render(<Provider store={store}><App/></Provider>, document.getElementById("root"));

    localStorage.setItem("react-token", keycloak.token);
    localStorage.setItem("react-refresh-token", keycloak.refreshToken);

    setTimeout(() => {
        keycloak.updateToken(70).success((refreshed) => {
            if (refreshed) {
                console.debug('Token refreshed' + refreshed);
            } else {
                console.warn('Token not refreshed, valid for '
                    + Math.round(keycloak.tokenParsed.exp + keycloak.timeSkew - new Date().getTime() / 1000) + ' seconds');
            }
        }).error(() => {
            console.error('Failed to refresh token');
        });


    }, 60000)

}).error(() => {
    console.error("Authenticated Failed");
});
HttpService.configure();